import React from 'react'
import ReactDOM from 'react-dom/client'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import App from './App'
import AcceptableUse from './components/AcceptableUse'
import AdaCompliance from './components/AdaCompliance'
import Contact from './components/Contact'
import Cookies from './components/Cookies'
import Error404 from './components/Error404'
import Pricing from './components/Pricing'
import PrivacyPolicy from './components/PrivacyPolicy'
import Resources from './components/Resources'
import Terms from './components/Terms'
import './index.css'
import reportWebVitals from './reportWebVitals'

const router = createBrowserRouter([
  { path: '/', element: <App /> },
  { path: '/privacy', element: <PrivacyPolicy /> },
  { path: '/contact', element: <Contact /> },
  { path: '/cookies', element: <Cookies /> },
  { path: '/acceptable-use', element: <AcceptableUse /> },
  { path: '/terms', element: <Terms /> },
  { path: '/ada-compliance', element: <AdaCompliance /> },
  { path: '/resources', element: <Resources /> },
  { path: '/categories/integrations', element: <Resources /> },
  { path: '/resources/slack', element: <Resources /> },
  { path: '/resources/intercom', element: <Resources /> },
  { path: '/pricing', element: <Pricing /> },
  { path: '*', element: <Error404 /> }
])

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
