import Lottie from 'lottie-react'
import { useState } from 'react'
import { Helmet } from 'react-helmet'
import analyticsAnimation from '../lottie/analytics-white.json'
import multitoolAnimation from '../lottie/multitool-slate.json'
import Footer from './Footer'
import Navbar from './Navbar'

function Pricing() {
  const [pricingPeriod, setPricingPeriod] = useState('monthly')

  return (
    <div className="App">
      <Helmet>
        <title>Pricing - Product Feedback Software - Feature Watch</title>
        <meta content="Pricing - Product Feedback Software - Feature Watch" property="og:title" />
        <meta content="Pricing - Product Feedback Software - Feature Watch" property="twitter:title" />
        <meta content="Simple product to get the feedback from your power users to the right people." name="description" />
        <meta content="Simple product to get the feedback from your power users to the right people." property="og:description" />
        <meta content="Simple product to get the feedback from your power users to the right people." property="twitter:description" />
      </Helmet>
      <div className="section header angle">
        <Navbar />
        <div class="container w-container">
          <div class="vertical-center-columns w-row">
            <div class="white w-col w-col-8 w-col-stack">
              <h1 class="header-h1">Pricing</h1>
              <p class="paragraph header-paragraph">Sign up now for a full-featured trial for 14 days with no obligations.</p>
              <a href="https://app.feature.watch/register" class="button w-button">
                Start a Free Trial
              </a>
            </div>
            <div class="w-col w-col-4 w-col-stack">
              <Lottie animationData={analyticsAnimation} className="lottie-animation analytics" />
            </div>
          </div>
        </div>
      </div>
      <div class="page-angle">
        <img src="/images/angle.png" alt="" />
      </div>
      <div class="section">
        <div class="container w-container">
          <div class="price-toggle-wrapper">
            <button
              onClick={() => setPricingPeriod('monthly')}
              class="price-text-button monthly"
              style={{
                backgroundColor: '#fff',
                borderColor: pricingPeriod === 'monthly' ? 'rgb(0, 172, 151)' : 'rgb(224, 223, 222)',
                color: pricingPeriod === 'monthly' ? 'rgb(0, 172, 151)' : 'rgb(224, 223, 222)',
                cursor: 'pointer'
              }}
            >
              Monthly
            </button>
            <button
              onClick={() => setPricingPeriod('yearly')}
              class="price-text-button yearly"
              style={{
                backgroundColor: '#fff',
                borderColor: pricingPeriod === 'yearly' ? 'rgb(0, 172, 151)' : 'rgb(224, 223, 222)',
                color: pricingPeriod === 'yearly' ? 'rgb(0, 172, 151)' : 'rgb(224, 223, 222)',
                cursor: 'pointer'
              }}
            >
              Yearly
            </button>
          </div>
          <div class="w-row">
            <div class="w-col w-col-4 w-col-stack">
              <div class="price-block">
                <img src="/images/pricing/5f0dab4c0da52e81d14bc5d2_watcher-icon-2.svg" alt="" class="pricing-icon" />
                <h2 class="price-plan-name">Watcher</h2>
                {pricingPeriod === 'monthly' && <h3 class="price monthly">$39/mo</h3>}
                {pricingPeriod === 'yearly' && <h3 class="price yearly">$399/yr</h3>}
                <p class="price-content">
                  For teams who just want to give a few members a little peek into whats going on. Start with a few boards to organize and track features with our basic
                  functionality. With all of our plans we won’t charge you more for having more users using your product.
                  <br />
                </p>
                <ul class="price-features">
                  <li>Unlimited Tracked Users</li>
                  <li>3 Team Members</li>
                  <li>1 Public Board</li>
                  <li>1 Private Board</li>
                  <li class="dim">Custom Status Labels</li>
                  <li class="dim">Custom Domain</li>
                  <li class="dim">Integrations</li>
                  <li class="dim">Developer API</li>
                  <li class="dim">Priority Support</li>
                </ul>
                <a href="https://app.feature.watch/register" class="button price-button w-button">
                  Start a Free Trial
                </a>
              </div>
            </div>
            <div class="w-col w-col-4 w-col-stack">
              <div class="price-block">
                <img src="/images/pricing/5f0dab4c0da52e0f444bc5d1_looker-icon-2.svg" alt="" class="pricing-icon" />
                <h2 class="price-plan-name">Lookout</h2>
                {pricingPeriod === 'monthly' && <h3 class="price monthly">$99/mo</h3>}
                {pricingPeriod === 'yearly' && <h3 class="price yearly">$999/yr</h3>}
                <p class="price-content">
                  For teams who want a little more control and flexibility with how they grow their products. Integrate with other productivity platforms to help augment your
                  experience
                  <br />
                </p>
                <ul class="price-features">
                  <li>Unlimited Tracked Users</li>
                  <li>Unlimited Team Members</li>
                  <li>3 Public Boards</li>
                  <li>3 Private Boards</li>
                  <li>Custom Status Labels</li>
                  <li>Custom Domain</li>
                  <li>Integrations</li>
                  <li class="dim">Developer API</li>
                  <li class="dim">Priority Support</li>
                </ul>
                <a href="https://app.feature.watch/register" class="button price-button w-button">
                  Start a Free Trial
                </a>
              </div>
            </div>
            <div class="w-col w-col-4 w-col-stack">
              <div class="price-block">
                <img src="/images/pricing/5f0dab4cc280cf5422788d2f_sentinel-icon-2.svg" alt="" class="pricing-icon" />
                <h2 class="price-plan-name">Sentinel</h2>
                <h3 class="price">Contact Us</h3>
                <p class="price-content">
                  For teams that just need it all. Contact us to talk about your needs.
                  <br />
                </p>
                <ul class="price-features">
                  <li>Unlimited Team Members</li>
                  <li>Unlimited Tracked Users</li>
                  <li>Unlimited Public Boards</li>
                  <li>Unlimited Private Boards</li>
                  <li>Custom Status Labels</li>
                  <li>Custom Domain</li>
                  <li>Integrations</li>
                  <li>Developer API</li>
                  <li>Priority Support</li>
                </ul>
                <a href="https://www.featurewatch.com/contact" class="button price-button w-button">
                  Contact Us
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section">
        <div class="container w-container">
          <div class="flex-wrapper-horizontal">
            <div class="featured-content right">
              <h2>Features that really Matter</h2>
              <ul>
                <li>Manageable Feature Boards</li>
                <li>Internal Comments / Workflow</li>
                <li>Embeddable Widget</li>
                <li>Custom Status Labels</li>
                <li>Integrations</li>
                <li>Custom Domain</li>
              </ul>
            </div>
            <Lottie animationData={multitoolAnimation} className="lottie-animation multitool" />
          </div>
        </div>
      </div>
      <div class="section teal">
        <div class="container cta w-container">
          <h2 class="white">Ready to get started with Feature Watch?</h2>
          <a href="https://app.feature.watch/register" class="button secondary w-button">
            Start a Free Trial
          </a>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Pricing
