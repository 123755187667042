import CookieConsent from 'react-cookie-consent'
import { Link } from 'react-router-dom'

function CookiePolicy() {
  return (
    <CookieConsent
      location="bottom"
      buttonText="Accept"
      cookieName="fw-cookie-consent"
      buttonClasses="button w-button secondary"
      containerClasses="cookie-notification"
      style={{ backgroundColor: '#00ac97', paddingTop: '0px', paddingBottom: '10px' }}
      buttonStyle={{
        position: 'relative',
        top: '25px',
        right: '60px',
        backgroundColor: '#00ac97',
        color: '#fff',
        fontSize: '18px',
        padding: '10px 15px',
        borderRadius: '20px',
        border: '1px solid #fff',
        transition: 'all .2s'
      }}
      expires={150}
    >
      <div className="container w-container" style={{ padding: '15px 100px' }}>
        <div className="cookie">
          Feature Watch uses cookies to improve your experience on our site. Before you continue, let us know you're okay with our use of cookies, in accordance with our{' '}
          <Link to="/cookies" className="white" target="_blank">
            Cookie Policy
          </Link>
          .
        </div>
      </div>
    </CookieConsent>
  )
}

export default CookiePolicy
