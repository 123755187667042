import Lottie from 'lottie-react'
import { Helmet } from 'react-helmet'
import Footer from './components/Footer'
import Navbar from './components/Navbar'
import collaborationAnimation from './lottie/collaboration-slate.json'
import communicationAnimation from './lottie/communication-white.json'
import crystalBallAnimation from './lottie/crystal-ball-slate.json'
import deliveryAnimation from './lottie/delivery-slate.json'
import multitoolAnimation from './lottie/multitool-slate.json'
import prototypingAnimation from './lottie/prototyping-slate.json'

function App() {
  return (
    <div className="App">
      <Helmet>
        <title>Feature Watch - Manage your users feedback</title>
        <meta content="Feature Watch - Manage your users feedback" property="og:title" />
        <meta content="Feature Watch - Manage your users feedback" property="twitter:title" />
        <meta
          name="description"
          content="Give your users the power to influence your product. Your product cannot survive without your users. Why not give them a voice to help you grow?"
        />
        <meta
          content="Give your users the power to influence your product. Your product cannot survive without your users. Why not give them a voice to help you grow?"
          property="og:description"
        />
        <meta
          content="Give your users the power to influence your product. Your product cannot survive without your users. Why not give them a voice to help you grow?"
          property="twitter:description"
        />
      </Helmet>
      <div className="section header homepage">
        <Navbar />
        <div className="container home-header w-container">
          <h1 className="header-h1">Give your users the power to influence your product</h1>
          <p className="paragraph header-paragraph">Why not give the ability to grow your product to the people who know it inside-out?</p>
          <a href="https://app.feature.watch/register" className="button w-button">
            Start a Free Trial
          </a>
          <Lottie animationData={communicationAnimation} className="lottie-animation communication" />
        </div>
      </div>
      <div className="header-point">
        <img src="images/index/header-point.png" alt="" />
      </div>
      <div className="section">
        <div className="container w-container">
          <div className="flex-wrapper-horizontal reverse-wrap">
            <div className="featured-content left">
              <h2>Your product cannot survive without your users. Why not give them a voice to help you grow?</h2>
              <p>Allow them to quickly easily make product enhancement suggestions based on their expertise.</p>
            </div>
            <Lottie animationData={collaborationAnimation} className="lottie-animation collaboration" />
            <img src="images/index/create-feature.png" width="370" alt="Create a feature request" className="mobile-image" />
          </div>
        </div>
      </div>
      <div className="section">
        <div className="container w-container">
          <div className="flex-wrapper-horizontal">
            <Lottie animationData={prototypingAnimation} className="lottie-animation prototyping" />
            <img src="images/index/productboard.png" width="370" alt="Create a feature request" className="move-image-left" />
            <div className="featured-content right">
              <h2>Use their feedback to build the features they want.</h2>
              <p>Take the most popular product suggestions, plan and execute them to help all your customers grow on your platform.</p>
            </div>
          </div>
        </div>
      </div>
      <div className="section">
        <div className="container w-container">
          <div className="flex-wrapper-horizontal reverse-wrap">
            <div className="featured-content left">
              <h2>Get real-time feedback as its given.</h2>
              <p>Track engagement easily with email and slack notifications.</p>
            </div>
            <img src="images/index/slack-light.png" width="370" alt="Slack Integration" className="move-image-right" />
            <Lottie animationData={deliveryAnimation} className="lottie-animation delivery" />
          </div>
        </div>
      </div>
      <div className="section">
        <div className="container w-container">
          <div className="flex-wrapper-horizontal">
            <img src="images/index/emailnotification.png" width="370" alt="Create a feature request" />
            <Lottie animationData={crystalBallAnimation} className="lottie-animation crystal-ball" />
            <div className="featured-content right">
              <h2>Close the loop and inform users of what you are working on and when its ready for them to use.</h2>
              <p>Users can track the progress of their suggestions with email notifications for predefined actions.</p>
            </div>
          </div>
        </div>
      </div>
      <div className="section">
        <div className="container w-container">
          <div className="flex-wrapper-horizontal">
            <div className="featured-content right">
              <h2>Features that really Matter</h2>
              <ul>
                <li>Manageable Feature Boards</li>
                <li>Internal Comments / Workflow</li>
                <li>Embeddable Widget</li>
                <li>Custom Status Labels</li>
                <li>Integrations</li>
                <li>Custom Domain</li>
              </ul>
            </div>
            <Lottie animationData={multitoolAnimation} className="lottie-animation multitool" />
          </div>
        </div>
      </div>
      <div className="section teal">
        <div className="container cta w-container">
          <h2 className="white">Ready to get started with Feature Watch?</h2>
          <a href="https://app.feature.watch/register" className="button secondary w-button">
            Start a Free Trial
          </a>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default App
