import { Helmet } from 'react-helmet'
import Footer from './Footer'
import Navbar from './Navbar'

function AdaCompliance() {
  return (
    <div className="App">
      <Helmet>
        <title>ADA Compliance - Feature Watch</title>
        <meta content="ADA Compliance - Feature Watch" property="og:title" />
        <meta content="ADA Compliance - Feature Watch" property="twitter:title" />
      </Helmet>
      <div className="section header angle">
        <Navbar />
        <div className="container header-left w-container">
          <h1 className="header-h1">ADA&nbsp;Compliance</h1>
          <p className="white">Last Updated: 9/3/2020</p>
        </div>
      </div>
      <div>
        <img src="/images/angle.png" alt="" />
      </div>
      <div className="section">
        <div className="container w-container">
          <div className="content-block w-richtext">
            <h2>Website Accessibility</h2>
            <p>
              At Feature Watch, it's important to us that our website is accessible and easy to use for all persons with disabilities. When creating our website, our team used
              software tools to identify web accessibility standards as outlined by the World Wide Web Consortium's Web Content Accessibility Guidelines 2.0 Level AA (WCAG 2.0 AA).
            </p>
            <p>
              While the industry is not operating from approved, regulated legislation, Feature Watch is committed to our good faith effort to follow the guidelines available. If
              you have questions, concerns, or have discovered an accessibility issue on our site, please contact us by emailing{' '}
              <a href="mailto:sarah@feature.watch?subject=ADA%20Compliance%20Question">sarah@feature.watch</a>. Be sure to include specifics and any page where an issue has
              occurred. We will make every reasonable effort to make the page accessible for you.
              <br />
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default AdaCompliance
