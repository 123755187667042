import Lottie from 'lottie-react'
import { Helmet } from 'react-helmet'
import mapCompassAnimation from '../lottie/map-compass-white.json'
import Footer from './Footer'
import Navbar from './Navbar'

function Error404() {
  return (
    <div className="App" style={{ backgroundColor: '#172b2c', height: '100vh' }}>
      <Helmet>
        <title>Not Found - Feature Watch</title>
        <meta content="Not Found - Feature Watch" property="og:title" />
        <meta content="Not Found - Feature Watch" property="twitter:title" />
      </Helmet>
      <div className="section header angle">
        <Navbar />
      </div>
      <div className="utility-page-wrap">
        <div className="utility-page-content w-form">
          <h2 className="header-h1">Did you get lost?</h2>
          <Lottie animationData={mapCompassAnimation} className="lottie-animation map-compass" />
          <div className="white">The page you are looking for doesn't exist or has been moved.</div>
        </div>
      </div>
      <div style={{ position: 'absolute', width: '100%', bottom: 0 }}>
        <Footer />
      </div>
    </div>
  )
}

export default Error404
