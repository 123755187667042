import { Fragment } from 'react'
import { Link, NavLink } from 'react-router-dom'
import CookiePolicy from './CookiePolicy'

function Footer() {
  return (
    <Fragment>
      <footer className="section footer">
        <div data-collapse="medium" data-animation="default" data-duration="400" data-easing="ease" data-easing2="ease" role="banner" className="navbar footer w-nav">
          <div className="w-nav-overlay" data-wf-ignore="" id="w-nav-overlay-1"></div>
        </div>
        <div className="container w-container">
          <div className="footer-nav">
            <Link to="/" className="brand w-nav-brand w--current">
              <img src="/images/index/FW- Color Logo Horizontal with Mark@2x.png" alt="Feature Watch Logo" className="logo" />
            </Link>
            <div className="footer-nav-links">
              <Link to="/pricing" className="nav-link footer w-inline-block">
                <div>Pricing</div>
              </Link>
              <Link to="/resources" className="nav-link footer w-inline-block">
                <div>Resources</div>
              </Link>
              <Link to="/contact" className="nav-link footer w-inline-block">
                <div>Contact Us</div>
              </Link>
              <a href="https://app.feature.watch/login" className="nav-link footer w-inline-block">
                <div>Sign&nbsp;In</div>
              </a>
              <div className="social-nav-links">
                <a href="https://www.linkedin.com/company/feature-watch-inc/" target="_blank" className="social-link w-inline-block" rel="noreferrer">
                  <img src="/images/index/linkedin-icon.svg" loading="lazy" alt="LinkedIn Icon" className="social-icon" />
                </a>
                <a href="https://twitter.com/FeatureWatch" target="_blank" className="social-link w-inline-block" rel="noreferrer">
                  <img src="/images/index/twitter-icon.svg" loading="lazy" alt="Twitter Icon" className="social-icon" />
                </a>
                <a href="https://www.facebook.com/FeatureWatch" target="_blank" className="social-link w-inline-block" rel="noreferrer">
                  <img src="/images/index/facebook-icon.svg" loading="lazy" alt="Facebook Icon" className="social-icon" />
                </a>
              </div>
            </div>
          </div>
          <div className="terms-nav-links">
            <NavLink to="/privacy" className={({ isActive }) => `nav-link footer small w-inline-block ${isActive ? 'w--current' : ''}`}>
              <div>Privacy Policy</div>
            </NavLink>
            <NavLink to="/cookies" className={({ isActive }) => `nav-link footer small w-inline-block ${isActive ? 'w--current' : ''}`}>
              <div>Cookie Policy</div>
            </NavLink>
            <NavLink to="/acceptable-use" className={({ isActive }) => `nav-link footer small w-inline-block ${isActive ? 'w--current' : ''}`}>
              <div>Acceptable Use Policy</div>
            </NavLink>
            <NavLink to="/terms" className={({ isActive }) => `nav-link footer small w-inline-block ${isActive ? 'w--current' : ''}`}>
              <div>Terms and Conditions</div>
            </NavLink>
            <NavLink to="/ada-compliance" className={({ isActive }) => `nav-link footer small w-inline-block ${isActive ? 'w--current' : ''}`}>
              <div>ADA Compliance</div>
            </NavLink>
          </div>
        </div>
      </footer>
      <CookiePolicy />
    </Fragment>
  )
}

export default Footer
