import Lottie from 'lottie-react'
import { useRef, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { Helmet } from 'react-helmet'
import contactAnimation from '../lottie/contact-slate.json'
import Footer from './Footer'
import Navbar from './Navbar'

function Contact() {
  const [showSuccess, setShowSuccess] = useState(false)
  const [showFail, setShowFail] = useState(false)
  const [buttonText, setButtonText] = useState('Submit')
  const [captchaResult, setCaptchaResult] = useState(null)
  const [errorText, setErrorText] = useState('Oops! Something went wrong while submitting the form.')
  const [isSubmitting, setIsSubmitting] = useState(false)

  const nameRef = useRef(null)
  const emailRef = useRef(null)
  const subjectRef = useRef(null)
  const descriptionRef = useRef(null)

  const emailRegex =
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/

  const onChange = (value) => {
    console.log('Captcha value:', value)
    setCaptchaResult(value)
  }

  const blinkFail = () => {
    setShowFail(true)
    setTimeout(() => setShowFail(false), 5000)
  }

  const toggleSubmitState = () => {
    setIsSubmitting((s) => !s)
    setButtonText((bt) => (bt === 'Submit' ? 'Please Wait' : 'Submit'))
  }

  const submitForm = async (e) => {
    toggleSubmitState()
    e.preventDefault()
    setShowFail(false)
    setShowSuccess(false)
    if (nameRef.current.value && emailRef.current.value && subjectRef.current.value && descriptionRef.current.value && emailRegex.test(emailRef.current.value) && captchaResult) {
      console.log(nameRef.current.value, emailRef.current.value, subjectRef.current.value, descriptionRef.current.value)
      console.log('form submitted')
      const response = await fetch('https://api.feature.watch/utils/contact', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          name: nameRef.current.value,
          email: emailRef.current.value,
          subject: subjectRef.current.value,
          description: descriptionRef.current.value,
          captcha: captchaResult
        })
      })
      console.log(response)

      if (response.status !== 202) {
        const responseJson = await response.json()
        setShowSuccess(false)
        setErrorText(responseJson.error || 'Unknown Error Occurred.')
        blinkFail()
      } else {
        setShowSuccess(true)
      }
    } else {
      if (!nameRef.current.value || !emailRef.current.value || !subjectRef.current.value || !descriptionRef.current.value) {
        setErrorText('All fields are required.')
      } else if (!captchaResult) {
        setErrorText('Captcha response is required.')
      } else if (!emailRegex.test(emailRef.current.value)) {
        setErrorText('Enter a valid email address.')
      }
      blinkFail()
    }
    toggleSubmitState()
  }

  return (
    <div className="App">
      <Helmet>
        <title>Contact Us - Feature Watch</title>
        <meta content="Contact Us - Feature Watch" property="og:title" />
        <meta content="Contact Us - Feature Watch" property="twitter:title" />
        <meta content="Have a question? Let us have it!" name="description" />
        <meta content="Have a question? Let us have it!" property="og:description" />
        <meta content="Have a question? Let us have it!" property="twitter:description" />
        <script src="https://www.google.com/recaptcha/api.js"></script>
      </Helmet>
      <div className="section header angle wf-section">
        <Navbar />
        <div className="container header-left w-container">
          <h1 className="header-h1">Contact Us</h1>
        </div>
      </div>
      <div className="wf-section">
        <img src="/images/angle.png" alt="" />
      </div>
      <div className="section wf-section">
        <div className="container w-container">
          <div className="contact-columns w-row">
            <div className="w-col w-col-6">
              <Lottie animationData={contactAnimation} className="lottie-animation contact" />
            </div>
            <div className="w-col w-col-6">
              <h2>Have a question? Fill out the form below!</h2>
              <div className="w-form">
                <form id="wf-form-Contact-Us-Form" name="wf-form-Contact-Us-Form" data-name="Contact Us Form" method="get" aria-label="Contact Us Form" onSubmit={submitForm}>
                  <input ref={nameRef} type="text" className="input-field w-input" maxLength="256" name="name" data-name="Name" placeholder="Name" id="name" required />
                  <input
                    ref={emailRef}
                    type="email"
                    className="input-field w-input"
                    maxLength="256"
                    name="email"
                    data-name="Email"
                    placeholder="Email Address"
                    id="email"
                    required
                  />
                  <input
                    ref={subjectRef}
                    type="text"
                    className="input-field w-input"
                    maxLength="256"
                    name="Subject"
                    data-name="Subject"
                    placeholder="Subject"
                    id="Subject"
                    required
                  />
                  <textarea
                    ref={descriptionRef}
                    placeholder="Tell us what is on your mind..."
                    maxLength="5000"
                    id="Description"
                    name="Description"
                    data-name="Description"
                    className="input-field w-input"
                    required
                  ></textarea>
                  <ReCAPTCHA sitekey="6Ld05LEZAAAAAJsFNALpNu-BU4RTqVb7zbTOOEul" onChange={onChange} />
                  <button disabled={isSubmitting} type="submit" className="button w-button" style={{ marginTop: '20px' }}>
                    {buttonText}
                  </button>
                </form>
                {showSuccess && (
                  <div className="w-form-done" tabIndex="-1" role="region" aria-label="Contact Us Form success">
                    <div>Thank you! Your submission has been received!</div>
                  </div>
                )}
                {showFail && (
                  <div className="w-form-fail" tabIndex="-1" role="region" aria-label="Contact Us Form failure">
                    <div>{errorText}</div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Contact
