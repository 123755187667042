import { Helmet } from 'react-helmet'
import { useLocation, useNavigate } from 'react-router-dom'
import Footer from './Footer'
import Navbar from './Navbar'

function GetHeader({ location }) {
  return (
    <Helmet>
      <title>Resources - Feature Watch</title>
      <meta content="Resources - Feature Watch" property="og:title" />
      <meta content="Resources - Feature Watch" property="twitter:title" />
      <meta content="Resources to help you get the most out of Feature Watch." name="description" />
      <meta content="Resources to help you get the most out of Feature Watch." property="og:description" />
      <meta content="Resources to help you get the most out of Feature Watch." property="twitter:description" />
    </Helmet>
  )
}

function Resources() {
  const { pathname } = useLocation()
  const navigate = useNavigate()

  const navigateResources = (to) => {
    navigate(to)
  }

  return (
    <div className="App">
      <GetHeader location={pathname} />
      <div className="section header angle">
        <Navbar />
        <div className="container header-left w-container">
          <h1 class="header-h1">Resources</h1>
        </div>
      </div>
      <div>
        <img src="/images/angle.png" alt="" />
      </div>
      <div className="section">
        <div class="container w-container">
          <div class="w-row">
            <div class="w-col w-col-3">
              <aside class="aside">
                <h3>Welcome!</h3>
                <div class="collection-list-wrapper w-dyn-list">
                  <div class="w-dyn-items">
                    <div role="listitem" class="w-dyn-item">
                      <a onClick={() => navigateResources('/categories/integrations')} class="aside-category-links" href="#">
                        Integrations
                      </a>
                      <div class="collection-list-wrapper-2 w-dyn-list">
                        <div class="w-dyn-items">
                          <div role="listitem" class="w-dyn-item">
                            <a onClick={() => navigateResources('/resources/intercom')} class="aside-category-links sub-link" href="#">
                              Intercom
                            </a>
                          </div>
                          <div role="listitem" class="w-dyn-item">
                            <a onClick={() => navigateResources('/resources/slack')} class="aside-category-links sub-link" href="#">
                              Slack
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </aside>
            </div>
            {pathname === '/resources' && (
              <div class="w-col w-col-9">
                <main class="resource-main">
                  <h2>Feature Watch</h2>
                  <p>
                    Click on a Category to the left to explore our resources.
                    <br />
                  </p>
                </main>
              </div>
            )}
            {pathname === '/categories/integrations' && (
              <div class="w-col w-col-9">
                <main class="resource-main">
                  <h2>Integrations</h2>
                  <p>Choose from any of our fantastic integrations below for more information on how to get them up and running with Feature Watch!</p>
                  <div class="w-dyn-list">
                    <div class="w-dyn-items">
                      <div role="listitem" class="w-dyn-item">
                        <a onClick={() => navigateResources('/resources/intercom')} class="integration-link w-inline-block" href="#">
                          <h3 class="integration-link">Intercom</h3>
                        </a>
                      </div>
                      <div role="listitem" class="w-dyn-item">
                        <a onClick={() => navigateResources('/resources/slack')} class="integration-link w-inline-block" href="#">
                          <h3 class="integration-link">Slack</h3>
                        </a>
                      </div>
                    </div>
                  </div>
                </main>
              </div>
            )}
            {pathname === '/resources/slack' && (
              <div class="w-col w-col-9">
                <a onClick={() => navigateResources('/categories/integrations')} class="tag" href="#">
                  Integrations
                </a>
                <h1>Slack</h1>
                <div class="w-richtext">
                  <h3>What this app does</h3>
                  <p>
                    Feature Watch allows you to be more productive from within Slack. Create new features, comment on activity and receive notifications for actions your team is
                    interested in.
                  </p>
                  <p>‍</p>
                  <h3>What you’ll need</h3>
                  <p>
                    A <strong>Feature Watch</strong> account on<strong> Lookout Plan</strong> or higher.
                  </p>
                  <p>‍</p>
                  <h3>How to set up the app</h3>
                  <p>To connect your Slack workspace to Feature Watch, perform the following actions:</p>
                  <ul>
                    <li>Navigate to Slack in settings and click Add to Slack Button to start Integration association</li>
                  </ul>
                  <figure style={{ 'max-width': '80%' }} class="w-richtext-align-normal w-richtext-figure-type-image">
                    <div>
                      <img src="/images/resources/5f41d794d7cadc2303b60e3c_fw_resources_slack_setup.png" loading="lazy" alt="" />
                    </div>
                  </figure>
                  <ul>
                    <li>Review Slack Application Permissions and choose a channel to integrate with by clicking Allow. </li>
                  </ul>
                  <figure style={{ 'max-width': '80%' }} class="w-richtext-align-normal w-richtext-figure-type-image">
                    <div>
                      <img src="/images/resources/5f3f17fba33abffeb8f562a0_fw_resources_slack_submit.png" alt="" />
                    </div>
                  </figure>
                  <p>‍</p>
                  <ul>
                    <li>You should now see the following notification settings in your workspace</li>
                  </ul>
                  <figure style={{ 'max-width': '80%' }} class="w-richtext-align-normal w-richtext-figure-type-image">
                    <div>
                      <img src="/images/resources/5f41d858cd7d090f13b8650d_fw_resources_slack_setup_2.png" loading="lazy" alt="" />
                    </div>
                  </figure>
                  <ul>
                    <li>Choose any or all notifications to be pushed into the linked channel</li>
                  </ul>
                  <p>‍</p>
                  <h3>Additional Features</h3>
                  <p>‍</p>
                  <h4>Commenting on Notifications</h4>
                  <p>‍</p>
                  <figure style={{ 'max-width': '40%' }} class="w-richtext-align-normal w-richtext-figure-type-image">
                    <div>
                      <img src="/images/resources/5f3f1a4f1fe77f6ec8f5a562_fw_resources_slack_add_comment.gif" alt="" />
                    </div>
                  </figure>
                  <p>‍</p>
                  <h4>Adding Features using Slack shortcut</h4>
                  <figure style={{ 'max-width': '40%' }} class="w-richtext-align-normal w-richtext-figure-type-image">
                    <div>
                      <img src="/images/resources/5f3f1eaf3ed9a62dc392e647_fw_resources_slack_add_feature.gif" alt="" />
                    </div>
                  </figure>
                </div>
              </div>
            )}
            {pathname === '/resources/intercom' && (
              <div class="w-col w-col-9">
                <a onClick={() => navigateResources('/categories/integrations')} class="tag" href="#">
                  Integrations
                </a>
                <h1>Intercom</h1>
                <div class="w-richtext">
                  <h3>What this app does</h3>
                  <p>The Feature Watch app makes it easy to collect and input feedback and feature requests in multiple ways within Intercom:</p>
                  <ul>
                    <li>Capture feature requests directly from within Messenger.</li>
                    <li>Search for and create features on behalf of your Users while talking to them in Inbox.</li>
                    <li>Send links to newly created or existing features with one click inside of conversations.</li>
                  </ul>
                  <p>‍</p>
                  <h3>What you’ll need</h3>
                  <p>
                    A <strong>Feature Watch</strong> account on<strong> Lookout Plan</strong> or higher.
                  </p>
                  <p>‍</p>
                  <h3>How to set up the app</h3>
                  <p>
                    <strong>1.</strong> <strong>Install integration in Feature Watch</strong>
                  </p>
                  <p>Navigate to Intercom section in Settings inside of Feature Watch and click Connect with Intercom button</p>
                  <p>​</p>
                  <figure class="w-richtext-align-normal w-richtext-figure-type-image">
                    <div>
                      <img src="/images/resources/5f5442e6086e1f626d99f845_Image+2020-09-05+at+9.21.26+PM.png" alt="" />
                    </div>
                  </figure>
                  <p>​</p>
                  <p>
                    <strong>2. Configure Default Board</strong>
                  </p>
                  <p>
                    After connecting Intercom you can configure the Default Board to be used when receiving features from within Messenger. We recommend that you use a private
                    board in this configuration setting. If you want to be able to review and moderate any requests entered this way through messengers, this will be the best way.
                    <br />​
                  </p>
                  <p>​</p>
                  <figure class="w-richtext-align-normal w-richtext-figure-type-image">
                    <div>
                      <img src="/images/resources/5f5442e7dc30fc83fbce93a8_Image+2020-09-05+at+9.24.42+PM.png" alt="" />
                    </div>
                  </figure>
                  <p>​</p>
                  <h3>How to use the app</h3>
                  <p>
                    <strong>1. Using with Messenger</strong>
                  </p>
                  <p>
                    Installing Feature Watch with Messenger will allow you to give your users or site visitors the ability to request features without having to talk to your
                    support team.{' '}
                  </p>
                  <p>​</p>
                  <figure class="w-richtext-align-normal w-richtext-figure-type-image">
                    <div>
                      <img src="/images/resources/5f5442e70a1bd2fc8b40c3ec_Image+2020-09-05+at+9.35.45+PM.png" alt="" />
                    </div>
                  </figure>
                  <p>​​</p>
                  <figure class="w-richtext-align-normal w-richtext-figure-type-image">
                    <div>
                      <img src="/images/resources/5f5442e678b8211f5e064f46_Image+2020-09-05+at+9.40.39+PM.png" alt="" />
                    </div>
                  </figure>
                  <p>​</p>
                  <p>
                    Once this is configured, you will have a new option in your Messenger for your visitor or user to request a new feature.
                    <br />​
                  </p>
                  <p>​</p>
                  <figure class="w-richtext-align-normal w-richtext-figure-type-image">
                    <div>
                      <img src="/images/resources/5f5442e70b72c23ac1855fe2_Image+2020-09-05+at+9.41.59+PM.png" alt="" />
                    </div>
                  </figure>
                  <p>​</p>
                  <p>
                    This will allow direct entry for feature requests into your Feature Watch instance.
                    <br />​
                  </p>
                  <p>
                    <strong>2. Using with Inbox</strong>
                  </p>
                  <p>
                    The Feature Watch Inbox widget will allow you to drop links to existing features directly into conversations or allow you to create new features on behalf of
                    your user or site visitor, crediting them with feature creation.
                  </p>
                  <p>
                    To get started, click the Feature Watch logo in the conversation editor
                    <br />​
                  </p>
                  <p>​</p>
                  <figure class="w-richtext-align-normal w-richtext-figure-type-image">
                    <div>
                      <img src="/images/resources/5f5442e75e6c33c00e49e32d_Image+2020-09-05+at+9.47.13+PM.png" alt="" />
                    </div>
                  </figure>
                  <p>​</p>
                  <p>
                    From the initial screen, you can search for a feature and then click on a result to drop a card for it into the conversation. If you need to add a new feature,
                    scroll to the bottom of the search results to find a Create new feature button
                    <br />​
                  </p>
                  <p>​</p>
                  <figure class="w-richtext-align-normal w-richtext-figure-type-image">
                    <div>
                      <img src="/images/resources/5f5442e76087a48a126d1b8f_Image+2020-09-05+at+9.49.00+PM.png" alt="" />
                    </div>
                  </figure>
                  <p>​</p>
                  <p>
                    Clicking the Create new feature button will bring you to a form to enter a new feature.
                    <br />​
                  </p>
                  <p>​</p>
                  <figure class="w-richtext-align-normal w-richtext-figure-type-image">
                    <div>
                      <img src="/images/resources/5f5442e765e74e59e556c4ec_Image+2020-09-05+at+9.50.02+PM.png" alt="" />
                    </div>
                  </figure>
                  <p>​</p>
                  <p>
                    From here you can select a board to add a feature to as well as the name and description. A couple things to note for this that are important. When a feature is
                    created here from within a conversation view, the feature will be created on behalf of the user with whom you are conversing with. So they will be marked as the
                    creator and automatically have an upvote applied and will be set as a watcher on the feature. If the lead or user does not have an email address in Intercom,
                    the teammate entering the feature will be marked as the creator.
                  </p>
                  <p>‍</p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Resources
