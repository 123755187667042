import { Helmet } from 'react-helmet'
import Footer from './Footer'
import Navbar from './Navbar'

function Cookies() {
  return (
    <div className="App">
      <Helmet>
        <title>Cookie Policy - Feature Watch</title>
        <meta content="Cookie Policy - Feature Watch" property="og:title" />
        <meta content="Cookie Policy - Feature Watch" property="twitter:title" />
        <meta content="We respect your privacy and take significant efforts to protect all your data. Security is a top priority at Feature Watch." name="description" />
        <meta content="We respect your privacy and take significant efforts to protect all your data. Security is a top priority at Feature Watch." property="og:description" />
        <meta
          content="We respect your privacy and take significant efforts to protect all your data. Security is a top priority at Feature Watch."
          property="twitter:description"
        />
      </Helmet>
      <div className="section header angle">
        <Navbar />
        <div className="container header-left w-container">
          <h1 className="header-h1">Cookie Policy</h1>
          <p className="white">Last Updated: 7/16/2020</p>
        </div>
      </div>
      <div>
        <img src="/images/angle.png" alt="" />
      </div>
      <div className="section">
        <div className="container w-container">
          <div className="content-block w-richtext">
            <p>
              This cookie policy ("Policy") describes what cookies are and how Feature Watch Inc. ("Feature Watch Inc.", "we", "us" or "our") uses them on the feature.watch website
              and any of its products or services (collectively, "Website" or "Services").
              <br />
              <br />
              You should read this Policy so you can understand what type of cookies we use, the information we collect using cookies and how that information is used. It also
              describes the choices available to you regarding accepting or declining the use of cookies. For further information on how we use, store and keep your personal data
              secure, see our Privacy Policy.
            </p>
            <h3>What are cookies?</h3>
            <p>
              Cookies are small pieces of data stored in text files that are saved on your computer or other devices when websites are loaded in a browser. They are widely used to
              remember you and your preferences, either for a single visit (through a "session cookie") or for multiple repeat visits (using a "persistent cookie").
            </p>
            <p>Session cookies are temporary cookies that are used during the course of your visit to the Website, and they expire when you close the web browser.</p>
            <p>
              Persistent cookies are used to remember your preferences within our Website and remain on your desktop or mobile device even after you close your browser or restart
              your computer. They ensure a consistent and efficient experience for you while visiting our Website or using our Services.
            </p>
            <p>
              Cookies may be set by the Website ("first-party cookies"), or by third parties, such as those who serve content or provide advertising or analytics services on the
              website ("third party cookies"). These third parties can recognize you when you visit our website and also when you visit certain other websites.
            </p>
            <h3>
              What type of cookies do we use?
              <br />
            </h3>
            <h4>Necessary cookies</h4>
            <p>
              Necessary cookies allow us to offer you the best possible experience when accessing and navigating through our Website and using its features. For example, these
              cookies let us recognize that you have created an account and have logged into that account to access the content.
            </p>
            <h4>Functionality cookies</h4>
            <p>
              Functionality cookies let us operate the Website and our Services in accordance with the choices you make. For example, we will recognize your username and remember
              how you customized the Website and Services during future visits.
            </p>
            <h4>Analytical cookies</h4>
            <p>
              These cookies enable us and third party services to collect aggregated data for statistical purposes on how our visitors use the Website. These cookies do not contain
              personal information such as names and email addresses and are used to help us improve your user experience of the Website.
              <br />
            </p>
            <h3>
              What are your cookie options?
              <br />
            </h3>
            <p>
              If you don't like the idea of cookies or certain types of cookies, you can change your browser's settings to delete cookies that have already been set and to not
              accept new cookies. To learn more about how to do this or to learn more about cookies, visit{' '}
              <a href="http://internetcookies.org/" target="_blank" rel="noreferrer">
                internetcookies.org
              </a>
            </p>
            <p>Please note, however, that if you delete cookies or do not accept them, you might not be able to use all of the features our Website and Services offer.</p>
            <h3>
              Changes and amendments
              <br />
            </h3>
            <p>
              We reserve the right to modify this Policy relating to the Website or Services at any time, effective upon posting of an updated version of this Policy on the
              Website. When we do we will revise the updated date at the bottom of this page. Continued use of the Website after any such changes shall constitute your consent to
              such changes.
            </p>
            <h3>
              Acceptance of this policy
              <br />
            </h3>
            <p>
              You acknowledge that you have read this Policy and agree to all its terms and conditions. By using the Website or its Services you agree to be bound by this Policy.
              If you do not agree to abide by the terms of this Policy, you are not authorized to use or access the Website and its Services.
            </p>
            <h3>
              Contacting us
              <br />
            </h3>
            <p>
              If you would like to contact us to understand more about this Policy or wish to contact us concerning any matter relating to our use of cookies, you may send an email
              to support@feature.watch
              <br />
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Cookies
