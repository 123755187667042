import { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import useWindowDimensions from './WindowDimensions'

const NavLinks = ({ navOpen }) => {
  return (
    <nav
      role="navigation"
      className={`nav-menu w-nav-menu open ${navOpen ? 'nav-menu-open' : ''}`}
      style={{ transform: 'translateY(0px) translateX(0px)', transition: navOpen ? 'transform 400ms ease 0s' : 'none' }}
    >
      <Link to="/pricing" className="nav-link first w-nav-link" style={{ maxWidth: '1200px' }}>
        Pricing
      </Link>
      <a href="https://app.feature.watch/login" className="nav-link w-nav-link" style={{ maxWidth: '1200px' }}>
        Sign In
      </a>
      <a href="https://app.feature.watch/register" className="button nav-button w-button">
        Start a Free Trial
      </a>
    </nav>
  )
}

function Navbar() {
  const [navOpen, setNavOpen] = useState(false)
  const { height } = useWindowDimensions()
  const { pathname } = useLocation()

  useEffect(() => {
    const canControlScrollRestoration = 'scrollRestoration' in window.history
    if (canControlScrollRestoration) {
      window.history.scrollRestoration = 'manual'
    }

    window.scrollTo(0, 0)
  }, [pathname])

  const toggleNav = () => {
    setNavOpen((navOpen) => !navOpen)
  }

  return (
    <div data-collapse="medium" data-animation="default" data-duration="400" data-easing="ease" data-easing2="ease" role="banner" className="navbar w-nav">
      <div className="container w-container">
        <Link to="/" className="brand w-nav-brand w--current">
          <img src="/images/index/FW- Color Logo Horizontal with Mark@2x.png" alt="Feature Watch Logo" className="logo" />
        </Link>
        {!navOpen && <NavLinks navOpen={navOpen} />}
        <div
          className="menu-button w-nav-button"
          style={{ WebkitUserSelect: 'text' }}
          aria-label="menu"
          role="button"
          tabIndex="0"
          aria-controls="w-nav-overlay-0"
          aria-haspopup="menu"
          aria-expanded="true"
          onClick={toggleNav}
        >
          <div className="menu-icon w-icon-nav-menu"></div>
        </div>
      </div>
      {navOpen && (
        <div className="w-nav-overlay open" style={{ height: height }} id="w-nav-overlay-0" onClick={toggleNav}>
          <NavLinks navOpen={navOpen} />
        </div>
      )}
    </div>
  )
}

export default Navbar
